import RestApiClient from "src/utils/RestApiClient";
import ApiUrls from "../../routes/api";
import BaseController from "../BaseController";
const api = new RestApiClient();

class Client extends BaseController {
  _list = ApiUrls.resources.client.index;

  _get = ApiUrls.resources.client.get;

  _post = ApiUrls.resources.client.create;

  _put = ApiUrls.resources.client.update;

  _delete = ApiUrls.resources.client.delete;

  async updateStatus(data) {
    return new Promise((resolve, reject) => {
      if (!data.id) reject({ message: "Update data should have id" });
      data.status = data.status === "inactive" ? "active" : "inactive";
      api
        .put(ApiUrls.resources.client.updateStatus(data.id), data)
        .then((res) => {
          if (res.result) {
            resolve("ok");
          } else {
            reject(res);
          }
        })
        .catch((err) => reject(err));
    });
  }
}


const clientInstacne = new Client();
export default clientInstacne;
