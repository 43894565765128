import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import {
  Button,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
// utils
// components
import { ViewGuard } from "src/auth/MyAuthGuard";
import { useSnackbar } from "src/components/snackbar";
import IconifyIcons from "src/config/IconifyIcons";
import ConfirmDialog from "../../../../../components/confirm-dialog";
import Iconify from "../../../../../components/iconify";
import MenuPopover from "../../../../../components/menu-popover";

// ----------------------------------------------------------------------
ClientTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onUpdateRow: PropTypes.func,

};

export default function ClientTableRow({
  row,
  selected,
  onUpdateRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const [statusConfirm, setStatusConfirm] = useState(false);

  const [status, setStatus] = useState(false);


  const handleDeleteRow = () => {
    onDeleteRow();
    window.ToastError("Client disabled successfully", { variant: "error" });
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setStatusConfirm(false);

    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleStatus = () => {
    setStatusConfirm(true);
  };

  const handleUpdateStatus = (e) => {
    setStatus(!status);
    localStorage.setItem(`${row.id}_status`, JSON.stringify(!status));
    onUpdateRow();
    handleCloseConfirm();
    window.Toast("Client Status updated successfully");
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">
          <Link
            noWrap
            color="inherit"
            variant="subtitle2"
            onClick={onViewRow}
            sx={{ cursor: "pointer" }}
          >
            {row?.name}
          </Link>
        </TableCell>

        <TableCell align="left">{row?.email ?? "-"}</TableCell>
        <TableCell align="left">{row?.contactNumber ?? "-"}</TableCell>

        <ViewGuard orPermissions={["resources.client.update"]}>
          <TableCell align="left">
            <Switch
              checked={row.status === "active"}
              labelplacement="start"
              label="Status"
              onClick={handleStatus}
            />
          </TableCell>
        </ViewGuard>
        <ViewGuard
          orPermissions={[
            "resources.client.update",
            "resources.client.delete",
            "resources.client.read",
          ]}
        >
          <TableCell align="left">
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        </ViewGuard>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <ViewGuard permission="resources.client.read">
          <MenuItem
            onClick={() => {
              onViewRow();
              handleClosePopover();
            }}
          >
            <Iconify icon={IconifyIcons.view} />
            View
          </MenuItem>
        </ViewGuard>
        <ViewGuard permission="resources.client.update">
          <MenuItem
            onClick={() => {
              onEditRow();
              handleClosePopover();
            }}
          >
            <Iconify icon={IconifyIcons.edit} />
            Edit
          </MenuItem>
        </ViewGuard>

        <Divider sx={{ borderStyle: "dashed" }} />

        <ViewGuard permission="resources.client.delete">
          {/* <MenuItem
            onClick={() => {
              handleOpenConfirm();
              handleClosePopover();
            }}
            sx={{ color: "error.main" }}
          >
            <Iconify icon={IconifyIcons.disable} />
            Disable
          </MenuItem> */}
        </ViewGuard>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Disable"
        content="Are you sure want to disable?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteRow}>
            Disable
          </Button>
        }
      />
      <ConfirmDialog
        open={statusConfirm}
        onClose={handleCloseConfirm}
        title="Client Status"
        content="Are you sure want to update Client status?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={handleUpdateStatus}
          >
            Update
          </Button>
        }
      />
    </>
  );
}
