import PropTypes from "prop-types";
// @mui
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
// utils
// components
import { useState } from "react";
import { ViewGuard } from "src/auth/MyAuthGuard";
import StatusChip from "src/components/StatusChip";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import Iconify from "src/components/iconify/Iconify";
import MenuPopover from "src/components/menu-popover/MenuPopover";

// ----------------------------------------------------------------------

IndentorTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function IndentorTableRow({
  row,
  selected,
  onDeleteRow,
  onEditRow,
}) {
  const [openPopover, setOpenPopover] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.contact}</TableCell>
        <TableCell align="left">{row.department}</TableCell>

        <TableCell align="left">
          <StatusChip status={row.status} />
        </TableCell>
        <ViewGuard
          orPermissions={[
            "resources.indentor.update",
            "resources.indentor.delete",
            "resources.indentor.read",
          ]}
        >
          <TableCell align="left">
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        </ViewGuard>
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <ViewGuard permission="resources.indentor.update">
          <MenuItem
            onClick={() => {
              onEditRow();
              handleClosePopover();
            }}
          >
            <Iconify icon="eva:edit-fill" />
            Edit
          </MenuItem>
        </ViewGuard>

        <Divider sx={{ borderStyle: "dashed" }} />
        <ViewGuard permission="resources.indentor.delete">
          <MenuItem
            onClick={() => {
              handleOpenConfirm();
              handleClosePopover();
            }}
            sx={{ color: "error.main" }}
          >
            <Iconify icon="ic:baseline-not-interested" />
            Delete
          </MenuItem>
        </ViewGuard>
      </MenuPopover>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
