import { useEffect, useState } from "react";

// form
import { useFormContext } from "react-hook-form";
// @mui
import { Box, Checkbox, Grid, Typography } from "@mui/material";

// utils
// components
import { Stack } from "@mui/system";
import { MuiTelInput } from "mui-tel-input";
import { countries } from "src/assets/data";
import DashedDivider from "src/components/DashedDivider";
import Client from "src/controller/resources/Client.controller";
import {
  RHFSelect,
  RHFTextField
} from "../../../../../components/hook-form";

// ----------------------------------------------------------------------

export default function ClientNewEditDetails({ isEdit }) {
  const {
    setValue,
    watch,
    formState,
    clearErrors,
    setError,
    trigger,

  } = useFormContext();
  const [billingAddressSameAsShipping, setBillingAddressSameAsShipping] =
    useState(false);
  const [gst, setGst] = useState([]);
  const [emails, setEmails] = useState([]);
  const [personEmails, setPersonEmails] = useState([]);
  const [contactNumber, setContactNumber] = useState("");
  const [contactNumberError, setcontactNumberError] = useState("");
  const [personContactNumber, setPersonContactNumber] = useState("");
  const [personPhoneNumberError, setPersonPhoneNumberError] = useState("");

  const values = watch();


  const handleBillingAddressChange = (event) => {
    trigger([
      "billing.address",
      "billing.city",
      "billing.state",
      "billing.country",
      "billing.pinCode",
    ]);

    const { address, city, state, stateCode, country, pinCode } =
      values.billing;

    if (event.target.checked) {
      setBillingAddressSameAsShipping(
        address && city && state && country && pinCode
      );

      setValue("shipping.address", address ?? "");
      setValue("shipping.city", city ?? "");
      setValue("shipping.state", state ?? "");
      setValue("shipping.stateCode", stateCode ?? "");
      setValue("shipping.country", country ?? "");
      setValue("shipping.pinCode", pinCode ?? "");

      trigger([
        "shipping.address",
        "shipping.city",
        "shipping.state",
        "shipping.country",
        "shipping.pinCode",
      ]);
    } else {
      setBillingAddressSameAsShipping(false);
    }
  };

  useEffect(() => {
    if (billingAddressSameAsShipping) {
      setValue("shipping.address", values.billing.address);
      setValue("shipping.city", values.billing.city);
      setValue("shipping.state", values.billing.state);
      setValue("shipping.stateCode", values.billing.stateCode);
      setValue("shipping.country", values.billing.country);
      setValue("shipping.pinCode", values.billing.pinCode);
    }
  }, [billingAddressSameAsShipping, setValue, values]);

  useEffect(() => {
    if (
      isEdit &&
      values.billing.address === values.shipping.address &&
      values.billing.city === values.shipping.city &&
      values.billing.state === values.shipping.state &&
      values.billing.country === values.shipping.country &&
      values.billing.pinCode === values.shipping.pinCode &&
      values.billing.stateCode === values.shipping.stateCode
    ) {
      setBillingAddressSameAsShipping(true);
    }
  }, [isEdit, values.billing, values.shipping]);

  useEffect(() => {
    Client.list()
      .then((res) => {
        setGst(res);
        setEmails(res);
        setPersonEmails(res);

      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    setContactNumber(values.contactNumber);
    setPersonContactNumber(values.personContactNumber);
  }, [values]);

  useEffect(() => {
    setcontactNumberError(formState.errors.contactNumber?.message ?? "");
    setPersonPhoneNumberError(
      formState.errors.personContactNumber?.message ?? ""
    );
  }, [formState.errors]);

  return (
    <Grid container spacing={1} sx={{ P: 2 }}>
      <Grid item xs={12} md={12}>
        <Box
          rowGap={2}
          columnGap={2}
          p={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        >
          <RHFTextField size="small" name="name" label="Name*" />
          <RHFTextField
            size="small"
            name="panNumber"
            label="PAN Number"
            onChange={(event) => {
              setValue("panNumber", event.target.value?.toUpperCase());
              clearErrors("panNumber");
            }}
          />
          <RHFTextField
            size="small"
            name="email"
            type="email"
            label="Email Address*"
            onChange={(event) => {
              const value = event.target.value;
              const isDuplicate = emails.filter((item) => item.email === value);

              if (isDuplicate && isDuplicate.length > 0) {
                setError("email", {
                  type: "manual",
                  message: "Duplicate Email ID",
                });
              } else {
                clearErrors("email");
              }
              setValue("email", value);
            }}
          />

          <MuiTelInput
            size="small"
            defaultCountry="IN"
            name="contactNumber"
            label="Contact Number*"
            value={contactNumber}
            onChange={(value) => {
              setContactNumber(value);
              setcontactNumberError("");
              setValue("contactNumber", value);
            }}
            error={Boolean(contactNumberError)}
            helperText={contactNumberError}
          />

          <RHFTextField
            size="small"
            name="gstNumber"
            label="GST Number"
            onChange={(event) => {
              setValue("gstNumber", event.target.value?.toUpperCase());
              const value = event.target.value;
              const isDuplicate = gst.filter(
                (item) => item.gstNumber === value
              );

              if (isDuplicate && isDuplicate.length > 0) {
                setError("gstNumber", {
                  type: "manual",
                  message: "Duplicate GST Number",
                });
              } else {
                clearErrors("gstNumber");
              }
              setValue("gstNumber", value);
            }}
          />
        </Box>

        <DashedDivider />

        <Stack>
          <Typography variant="h6" pl={2}>
            Billing Address
          </Typography>
        </Stack>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{ width: 1, p: 2 }}
        >
          <RHFTextField
            size="small"
            name="billing.address"
            label="Address*"
            sx={{ maxWidth: { md: 900 } }}
          />
          <RHFTextField
            size="small"
            name="billing.city"
            label="City*"
            sx={{ maxWidth: { md: 600 } }}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{ width: 1, pl: 2, pr: 2 }}
          direction={{ xs: "column", md: "row" }}
        >
          <RHFTextField
            size="small"
            name="billing.state"
            label="State*"
            sx={{ maxWidth: { md: 350 } }}
          />
          <RHFTextField
            size="small"
            name="billing.stateCode"
            label="State Code"
            sx={{ maxWidth: { md: 350 } }}
          />

          <RHFSelect
            size="small"
            native
            name="billing.country"
            label="Country*"
            sx={{ maxWidth: { md: 350 } }}
          >
            <option value="" />
            {countries.map((country) => (
              <option key={country.code} value={country.label}>
                {country.label}
              </option>
            ))}
          </RHFSelect>

          <RHFTextField
            size="small"
            name="billing.pinCode"
            label="PIN Code*"
            sx={{ maxWidth: { md: 350 } }}
          />
        </Stack>

        <DashedDivider />

        <Stack>
          <Typography variant="h6" pl={2}>
            Shipping Address
          </Typography>
        </Stack>

        <Stack
          display="flex"
          direction="row"
          justifyContent="flex-start"
          pl={1}
        >
          <Checkbox
            checked={billingAddressSameAsShipping}
            onChange={handleBillingAddressChange}
          />
          <Typography sx={{ mt: 1 }}>Same as Billing Address</Typography>
        </Stack>

        <Stack
          rowGap={1}
          columnGap={1}
          display="grid"
          pl={3}
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
          }}
        ></Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{ width: 1, p: 2 }}
        >
          <RHFTextField
            size="small"
            name="shipping.address"
            label="Address*"
            sx={{ maxWidth: { md: 900 } }}
          />
          <RHFTextField
            size="small"
            name="shipping.city"
            label="City*"
            sx={{ maxWidth: { md: 600 } }}
          />
        </Stack>
        <Stack
          spacing={2}
          sx={{ width: 1, pl: 2, pr: 2 }}
          direction={{ xs: "column", md: "row" }}
        >
          <RHFTextField
            size="small"
            name="shipping.state"
            label="State*"
            sx={{ maxWidth: { md: 350 } }}
          />
          <RHFTextField
            size="small"
            name="shipping.stateCode"
            label="State Code"
            sx={{ maxWidth: { md: 350 } }}
          />
          <RHFSelect
            size="small"
            native
            name="shipping.country"
            label="Country*"
          >
            <option value="" />
            {countries.map((country) => (
              <option key={country.code} value={country.label}>
                {country.label}
              </option>
            ))}
          </RHFSelect>

          <RHFTextField
            size="small"
            name="shipping.pinCode"
            label="PIN Code*"
            sx={{ maxWidth: { md: 350 } }}
          />
        </Stack>

        <DashedDivider />

        <Stack>
          <Typography variant="h6" pl={2}>
            Contact Person
          </Typography>
        </Stack>

        <Stack
          rowGap={2}
          columnGap={2}
          sx={{ p: 2 }}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(3, 1fr)",
          }}
        >
          <RHFTextField
            size="small"
            name="personName"
            label="Name*"
          />
          <RHFTextField
            size="small"
            name="personEmail"
            label="Email*"
            onChange={(event) => {
              const value = event.target.value;
              const isDuplicate = personEmails.filter(
                (item) => item.personEmail === value
              );

              if (isDuplicate && isDuplicate.length > 0) {
                setError("personEmail", {
                  type: "manual",
                  message: "Duplicate Email ID",
                });
              } else {
                clearErrors("personEmail");
              }
              setValue("personEmail", value);
            }}
          />

          <Stack>
            <MuiTelInput
              size="small"
              defaultCountry="IN"
              name="personContactNumber"
              label="Contact Number*"
              value={personContactNumber}
              onChange={(value) => {
                setPersonContactNumber(value);
                setPersonPhoneNumberError("");
                setValue("personContactNumber", value);
              }}
              error={Boolean(personPhoneNumberError)}
              helperText={personPhoneNumberError}
            />
          </Stack>

        </Stack>

      </Grid>
    </Grid>
  );
}
