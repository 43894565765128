import { Loadable, lazy } from "src/routes/utils";

export const IGIListPage = Loadable(lazy(() => import("./IGIListPage")));
export const IGICreatePage = Loadable(
  lazy(() => import("src/pages/dashboard/inventory/igi/IGICreatePage"))
);

export const IGIViewPage = Loadable(
  lazy(() => import("src/pages/dashboard/inventory/igi/IGIViewPage"))
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: "igi",
  children: [
    {
      element: <IGIListPage />,
      index: true,
    },
    {
      path: ":id",
      element: <IGICreatePage />,
    },
    {
      path: ":id/view",
      element: <IGIViewPage />,
    },
  ],
};
