import { Loadable, lazy } from "src/routes/utils";
import ClientViewPage from "./ClientViewPage";


export const ClientListPage = Loadable(
  lazy(() => import("./ClientListPage"))
);

export const ClientCreatePage = Loadable(
  lazy(() => import("./ClientCreatePage"))
);



const ObjectRoot = {
  path: "Client",
  children: [
    { element: <ClientListPage />, index: true },
    {
      path: "new",
      element: <ClientCreatePage />,
    },
    {
      path: ":id/edit",
      element: <ClientCreatePage />,
    },
    {
      path: ":id/view",
      element: <ClientViewPage />,
    },
  ],
}
export default ObjectRoot
