import { Helmet } from "react-helmet-async";
// @mui
import { Button, Card, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@mui/material";
// routes
import { useParams } from "react-router";
import { PATH_DASHBOARD } from "../../../../routes/paths";
// components
import Client from "src/controller/resources/Client.controller";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../../components/settings";
// sections
import {
  Box,
  Divider,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import InlineText, { InlineTextContainer } from "src/components/InlineText";

import { ViewGuard } from "src/auth/MyAuthGuard";
import { TableNoData } from "src/components/table";

// ----------------------------------------------------------------------
const TABLE_HEAD = ["Name", "Email", "Contact Number"];
// ----------------------------------------------------------------------


export default function ClientViewPage() {
  const { themeStretch } = useSettingsContext();
  const [values, setValues] = useState({});

  const { id } = useParams();
  useEffect(() => {

    Client.get(id)
      .then((res) => {
        setValues(res);
      })
      .catch((err) => console.log(err));

  }, [id]);

  return (
    <ViewGuard permission="resources.client.read" page={true}>
      <Helmet>
        <title>Resources : Client </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs

          heading={
            <p>
              Client ({values.name})
            </p>}
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "resources",
            },
            {
              name: " Client",
              href: PATH_DASHBOARD.resources.client.root,
            },
            {
              name: <p>{values.name}{" "}</p>
            },
          ]}
        />

        <Stack>
          <Card sx={{ p: 2 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <InlineTextContainer>
                <InlineText tag="Client Name:"
                  value={values?.name} />
                <InlineText
                  tag="Client Email:"
                  value={values?.email}
                />
                <InlineText
                  tag="Contact Number:"
                  value={values?.contactNumber}
                />
                <InlineText
                  tag=" GST Number:"
                  value={values?.gstNumber}
                />
                <InlineText tag="PAN Number:" value={values?.panNumber} />

              </InlineTextContainer>

            </Box>
            <Divider sx={{ my: 2, borderStyle: "dashed" }} />
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >

              <Stack >
                <Typography sx={{ pb: 1 }} variant="h6">Billing Address</Typography>
                <InlineTextContainer>
                  <InlineText tag="Address:" value={values?.billing?.address} />
                  <InlineText tag="City:" value={values?.billing?.city} />
                  <InlineText tag="State:" value={values?.billing?.state} />
                  <InlineText tag="State Code:" value={values?.billing?.stateCode} />
                  <InlineText tag="Country:" value={values?.billing?.country} />
                  <InlineText tag="PIN Code:" value={values?.billing?.pinCode} />

                </InlineTextContainer>
              </Stack>

              <Stack>
                <Typography sx={{ pb: 1 }} variant="h6">Shipping Address</Typography>
                <InlineTextContainer>
                  <InlineText tag="Address:" value={values?.shipping?.address} />
                  <InlineText tag="City:" value={values?.shipping?.city} />
                  <InlineText tag="State:" value={values?.shipping?.state} />
                  <InlineText tag="State Code:" value={values?.shipping?.stateCode} />
                  <InlineText tag="Country:" value={values?.shipping?.country} />
                  <InlineText tag="PIN Code:" value={values?.shipping?.pinCode} />
                </InlineTextContainer>
              </Stack>
            </Box>
            <Divider sx={{ my: 2, borderStyle: "dashed" }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Contact Details
            </Typography>

            <TableContainer sx={{ position: "relative", overflow: "unset" }}>
              <Table
                sx={{ minWidth: 859, width: "100%" }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((item) => (
                      <TableCell key={item}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow hover>
                    <TableCell>{values?.personName ?? "-"}</TableCell>
                    <TableCell>{values?.personEmail ?? "-"}</TableCell>
                    <TableCell>{values?.personContactNumber ?? "-"}</TableCell>
                  </TableRow>
                  <TableNoData isNotFound={values?.length === 0} />
                </TableBody>
              </Table>
            </TableContainer>

          </Card>

          <Stack
            justifyContent="flex-end"
            direction="row"
            spacing={2}
            sx={{ mt: 3 }}
          >
            <ViewGuard permission="resources.client.update">
              <Button
                size="large"
                variant="contained"
                onClick={() => window.redirect(PATH_DASHBOARD.resources.client.edit(id))}
              >
                Edit
              </Button>
            </ViewGuard>

            <Button
              color="error"
              size="large"
              variant="contained"
              onClick={() =>
                window.redirect(PATH_DASHBOARD.resources.client.root)
              }
            >
              Back
            </Button>
          </Stack>
        </Stack>
      </Container >
    </ViewGuard>
  );
}
