import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
// routes
import { useParams } from "react-router";
import { PATH_DASHBOARD } from "../../../../routes/paths";
// components
import ClientNewEditForm from "src/sections/@dashboard/resources/client/form/ClientNewEditForm";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../../components/settings";
// sections

// ----------------------------------------------------------------------

export default function ClientCreatePage() {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>
          Resources : {id ? "Update Client" : "Create a new Client"}{" "}
        </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading={id ? "Edit Client" : "Add Client"}
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Client",
            },
            {
              name: "Client",
              href: PATH_DASHBOARD.resources.client.root,
            },
            {
              name: id ? "Update Client" : "Add Client",
            },
          ]}
        />

        <ClientNewEditForm isEdit={id ?? false} />
      </Container>
    </>
  );
}
