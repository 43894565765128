import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Card, Stack } from "@mui/material";
// routes
import { useParams } from "react-router";
import Client from "src/controller/resources/Client.controller";
import { PATH_DASHBOARD } from "../../../../../routes/paths";
// mock
// components
import { phone } from "phone";
import FormProvider from "../../../../../components/hook-form";
import ClientNewEditDetails from "../form/ClientNewEditDetails";

// ----------------------------------------------------------------------

ClientNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentPurchase: PropTypes.object,
};

export default function ClientNewEditForm({ isEdit, data }) {
  const navigate = useNavigate();

  const [loadingSave, setLoadingSave] = useState(false);

  const [loadingSend, setLoadingSend] = useState(false);

  const { id } = useParams();

  const defaultValues = useMemo(
    () => ({
      name: "",
      email: "",
      contactNumber: "",
      gstNumber: "",
      panNumber: "",
      billing: {
        address: "",
        city: "",
        state: "",
        stateCode: "",
        country: "",
        pinCode: "",
      },
      shipping: {
        address: "",
        city: "",
        state: "",
        stateCode: "",
        country: "",
        pinCode: "",
      },
      personName: "",
      personEmail: "",
      personContactNumber: "",
    }),
    []
  );

  const ClientSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter valid name"),

    email: Yup.string()
      .required("Client email is required")
      .email("Client email must be a valid email address"),

    contactNumber: Yup.string()
      .test("is-contact-valid", "Invalid contact number", (value) => {
        return phone(value).isValid;
      }),

    panNumber: Yup.string()
      .trim()
      .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, "Invalid Pan Card Number"),

    gstNumber: Yup.string()
      .trim()
      .matches(
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}Z[0-9a-zA-Z]{1}$/,
        "Enter valid GST Number"
      ),

    personName: Yup.string()
      .required("Contact name is required")
      .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter valid name"),

    personEmail: Yup.string()
      .required("Contact email is required")
      .email("Contact email must be a valid email address"),


    personContactNumber: Yup.string()
      .test("is-contact-valid", "Invalid contact number", (value) => {
        return phone(value).isValid;
      })
      .required("Contact number is required"),

    shipping: Yup.object().shape({
      address: Yup.string().required("Shipping address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      pinCode: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]{6}$/, "Enter valid pincode"),
    }),

    billing: Yup.object().shape({
      address: Yup.string().required("Billing address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      country: Yup.string().required("Country is required"),
      pinCode: Yup.string()
        .required("Pincode is required")
        .matches(/^[0-9]{6}$/, "Enter valid pincode"),
    }),
  });

  ClientSchema.fields.panNumber = Yup.string().when("panNumber", {
    is: (value) => value && value.trim().length > 0,
    then: Yup.string().matches(
      /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
      "Invalid Pan Card Number"
    ),
    otherwise: Yup.string(),
  });

  ClientSchema.fields.gstNumber = Yup.string().when("gstNumber", {
    is: (value) => value && value.trim().length > 0,
    then: Yup.string().matches(
      /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}Z[0-9a-zA-Z]{1}$/,
      "Invalid GST Number"
    ),
    otherwise: Yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(ClientSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleSaveAsDraft = () => {
    setLoadingSave(false);
    navigate(PATH_DASHBOARD.resources.client.root);
  };

  const handleCreateAndSend = async (data) => {

    setLoadingSend(true);

    setTimeout(() => {
      if (isEdit) {
        Client.update(data.id, data)
          .then((res) => {
            window.Toast("Client update successfully");
            navigate(PATH_DASHBOARD.resources.client.root);
          })
          .catch((error) => {
            setLoadingSend(false);
            window.ToastError(error.message);
          });
      } else {
        Client.create(data)
          .then((res) => {
            window.Toast("Client created successfully");
            navigate(PATH_DASHBOARD.resources.client.root);
          })
          .catch((error) => {
            setLoadingSend(false);
            window.ToastError(error.message);
          });
      }
    }, 1000);
  };

  useEffect(() => {
    if (isEdit) {
      Client.get(id)
        .then((res) => {
          reset({
            ...defaultValues,
            ...res,

          });
        })
        .catch((err) => console.log(err));
    }
  }, [defaultValues, id, isEdit, reset]);

  return (
    <FormProvider methods={methods}>
      <Card>
        <ClientNewEditDetails isEdit={id ?? false} />
      </Card>

      <Stack
        justifyContent="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 3 }}
      >
        <LoadingButton
          size="large"
          variant="contained"
          loading={loadingSend || isSubmitting}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {id ? "Update" : "Add "}
        </LoadingButton>

        <LoadingButton
          color="error"
          size="large"
          variant="contained"
          loading={loadingSave && isSubmitting}
          onClick={handleSaveAsDraft}
        >
          Cancel
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
